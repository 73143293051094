import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { Link } from "gatsby"
import * as actions from "../state/actions/keymans"
import { PREFECTURES } from "../constants/constants"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import { Location } from "@reach/router"
import Map from "./../images/map.svg"
import BreadcrumbList from "../components/common/breadcrumb-list"
import Banner from "../components/common/banner"
import Card from "../components/common/card"
import ServiceDescription from "../components/atoms/service-description"
import LinkToLpText from "../components/atoms/link-to-lp-text"
import LinkToUpdate from "../components/common/link-to-update"
import classNames from "classnames"

const mapStateToProps = (state, ownProps) => {
  return {
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    keymanCountYstd: state.global.keymanCountYstd,
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
  }
}

const styles = theme => ({
  map: {
    width: '71.5%',
    height: 'auto',
    [theme.breakpoints.down("xs")]: {
      width: '76%',
    },
  },

  link: {
    color: '#181819',
  },

  container: {
    width: '100%',
    height: 'auto',
    position: 'relative',
    background: '#EFF7FF',
    padding: '10px 0',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      padding: '20px 0 70px',
    },
  },

  prefectureTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '0 -15px',
    [theme.breakpoints.down("xs")]: {
      fontSize: '12px',
    },
  },

  prefectureContent: {
    position: 'absolute',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #00000026;',
    padding: '10px 11px 6px 10px',
    width: 'calc(22px * 3 + 30px)',
    borderRadius: '2px',
    [theme.breakpoints.down("xs")]: {
      width: 'calc(18px * 3 + 30px)',
      padding: '5px 3px 5px 2px',
    },
  },

  hokkaidoContent: {
    top: '10%',
    right: '30%',
    width: '12%',
    [theme.breakpoints.down("xs")]: {
      width: '15%',
    },
  },

  tohokuContent: {
    top: '33%',
    right: '11%',
    [theme.breakpoints.down("xs")]: {
      right: '8%',
      top: '27%',
    },
  },

  hokurikuContent: {
    right: '38%',
    padding: '10px 31px 6px 30px',
    top: '38%',
    [theme.breakpoints.between("xs", "md")]: {
      top: '35%',
      padding: '10px 26px 6px 25px',
      right: '40%',
    },
    [theme.breakpoints.down("xs")]: {
      padding: '5px 6px 5px 5px',
      top: '38%',
      right: '38%',
    },
  },

  kantoContent: {
    top: '52%',
    right: '12%',
    [theme.breakpoints.between("xs", "md")]: {
      right: '10%',
    },
    [theme.breakpoints.down("xs")]: {
      top: '44%',
      right: '6%',
    },
  },

  tokaiContent: {
    padding: '10px 26px 6px 25px',
    top: '61%',
    right: '32%',
    width: 'calc(17px * 2 + 30px)',
    [theme.breakpoints.between("xs", "md")]: {
      padding: '10px 15px 6px 14px',
      right: '33%',
      top: '60%',
    },
    [theme.breakpoints.down("xs")]: {
      padding: '5px 6px 5px 5px',
      width: 'calc(13px * 2 + 30px)',
      top: '55%',
      right: '36%',
    },
  },

  kansaiContent: {
    bottom: '10%',
    right: '33%',
    width: 'calc(22px * 3 + 41px)',
    [theme.breakpoints.between("xs", "md")]: {
      bottom: '7%',
      right: '27%',
    },
    [theme.breakpoints.down("xs")]: {
      width: 'calc(20px * 3 + 41px)',
      bottom: '15%',
      right: '26%',
      padding: '5px 1px 5px 0',
    },
  },

  shikokuContent: {
    padding: '10px 26px 6px 25px',
    bottom: '6%',
    left: '27%',
    width: 'calc(17px * 2 + 30px)',
    [theme.breakpoints.between("xs", "md")]: {
      padding: '10px 15px 6px 14px',
      left: '30%',
    },
    [theme.breakpoints.down("xs")]: {
      padding: '5px 6px 5px 5px',
      width: 'calc(13px * 2 + 30px)',
      left: '20%',
      bottom: '21%',
    },
  },

  chugokuContent: {
    top: '54%',
    left: '25%',
    [theme.breakpoints.between("xs", "md")]: {
      top: '52%',
      left: '21%',
    },
    [theme.breakpoints.down("xs")]: {
      top: '49%',
      left: '4%',
    },
  },

  kyusyuContent: {
    padding: '10px 31px 6px 30px',
    bottom: '5%',
    left: '1%',
    [theme.breakpoints.between("xs", "md")]: {
      padding: '10px 26px 6px 25px',
      bottom: '2%',
    },
    [theme.breakpoints.down("xs")]: {
      padding: '5px 6px 5px 5px',
      left: '1%',
      bottom: '1%',
    },
  },

  row: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden',
    margin: '2px auto 0px',
    lineHeight: 1.35,
    [theme.breakpoints.down("xs")]: {
      lineHeight: 1.25,
      margin: '1px auto 0',
    },
  },

  item: {
    fontSize: '11px',
    padding: '0 4px 0 6px',
    position: 'relative',
    [theme.breakpoints.down("xs")]: {
      fontSize: '9px',
    },
    '&:not(:last-child)::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: '-2px',
      transform: 'translate(-50%)',
      top: '2px',
      width: '1px',
      height: '10px',
      background: '#181819',
      [theme.breakpoints.down("xs")]: {
        top: '1px',
        height: '8px',
      },
    }
  },

  spacer: {
    width: '100%',
  },

  shiga: {
    textAlign: 'right',
    width: '27px',
    [theme.breakpoints.down("xs")]: {
      width: '27px',
    },
  },

  kyoto: {
    textAlign: 'left',
    width: '28px',
    [theme.breakpoints.down("xs")]: {
      width: '26px',
    },
  },

  tokyo: {
    textAlign: 'right',
    width: '37px',
    [theme.breakpoints.down("xs")]: {
      width: '32px',
    },
  },

  kanagawa: {
    textAlign: 'left',
    width: '39px',
    [theme.breakpoints.down("xs")]: {
      width: '32px',
    },
  },
})

class CorporationsLocationSearch extends React.Component {
  componentDidMount() {
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  renderPrefecturesMap = (prefectures, index) => {
    const { classes } = this.props
    return (
      <Link key={prefectures.name} className={classes.link} to={`/corporations-location-search/${prefectures.value}`}>
        <div className={classNames(classes[`${prefectures.name}Content`], classes.prefectureContent)}>
          <div className={classes.prefectureTitle}>{prefectures.value}
          </div>
          <div className={classes.row}>
          <div className={classes.spacer}></div>
            {prefectures.submenu.map((prefecture) => {
              return (
                <div key={prefecture.name} className={classNames(classes[prefecture.name], classes.item)}>
                  {prefecture.value.indexOf('道') === -1 ? prefecture.value.slice(0, -1) : prefecture.value}
                </div>
              )
            })}
          </div>
        </div>
      </Link>
    )
  }

  render() {
    const {
      classes,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      updateKeymans,
      isLoadingUpdateKeymans,
    } = this.props

    return (
      <Layout hasFooterPopup={true}>
        <SEO
          title={`地域から企業を探す`}
          description={`地域から企業を探すことができます。大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
          canonical={`https://keyman-db.smart-letter.com/corporations-location-search`}
        />
        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={[
                  { path: "/", text: "トップ" },
                ]}
                current={{
                  path: `/corporations-location-search`,
                  text: `地域から企業を探す`,
                }}
              />
              <div>
                <h2 className="title">
                地域から企業を探す
                </h2>
                <ServiceDescription
                  children={<LinkToLpText/>}
                />
              </div>
              <div className={classes.container}>
                <img
                  alt="map.svg"
                  src={Map}
                  width={203}
                  height={150}
                  className={classes.map}
                />
                {PREFECTURES.map((info, i) => this.renderPrefecturesMap(info, i))}
              </div>
            </main>
            <div className="right">
              <div className="banner">
              <Banner short={true} />
              </div>
              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={updateKeymans}
                isLoadingUpdateKeymans={isLoadingUpdateKeymans}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <CorporationsLocationSearch {...locationProps} {...props} />}
    </Location>
  ))
)
