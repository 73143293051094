import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  root: {
    textDecoration: "none",
  },
})

const LinkToLpTheme = styled.a`
  & {
    color: ${({theme}) => theme.color};
    fontSize: ${({theme}) => theme.fontSize};
  }
`;

const LinkToLpText = ({ classes, color, fontSize, text }) => {
  return (
    <LinkToLpTheme
      href="https://keymanletter.com/executive-list/"
      target="_blank"
      rel="noopener"
      className={classes.root}
      theme={{ color: color, fontSize: fontSize }}
    >
      {`${text}`}
    </LinkToLpTheme>
  )
}

LinkToLpText.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  text: PropTypes.string,
}

LinkToLpText.defaultProps = {
  color: "#FFBB02",
  fontSize: "13px",
  text: "詳細はこちら。"
}

export default withStyles(styles)(LinkToLpText)
