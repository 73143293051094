import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
})

const ServiceDescriptionTheme = styled.p`
  & {
    margin: ${({theme}) => theme.margin};
    fontSize: ${({theme}) => theme.fontSize};
  }
`;

const ServiceDescription = ({ classes, margin, fontSize, text , children = null}) => {
  return (
    <ServiceDescriptionTheme
      href="https://keymanletter.com/executive-list/"
      target="_blank"
      rel="noopener"
      className={classes.root}
      theme={{ margin: margin, fontSize: fontSize }}
    >
      {`${text}`}
      { children }
    </ServiceDescriptionTheme>
  )
}

ServiceDescription.propTypes = {
  margin: PropTypes.string,
  fontSize: PropTypes.string,
  text: PropTypes.string,
}

ServiceDescription.defaultProps = {
  margin: "34px 0px",
  fontSize: "13px",
  text: "Keyman Letterでは全国数十万社から会社の決裁者を検索し、詳細の閲覧・手紙の送付や営業リスト作成を行えるサービスです。"
}

export default withStyles(styles)(ServiceDescription)
