import React from "react"
import PropTypes from "prop-types"

/**
 * 「>」のアイコン
 */
const ArrowRight = props => {
  const { color, width, height, ...other } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <defs></defs>
      <g
        id="arrow-right"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M7.58710594,7.7655654 L13,13.1666667 L18.4128941,7.7655654 C18.8035426,7.37576799 19.4361129,7.3761129 19.8263361,7.76633609 L20.2928932,8.23289322 C20.6834175,8.62341751 20.6834175,9.25658249 20.2928932,9.64710678 L13.7071068,16.2328932 C13.3165825,16.6234175 12.6834175,16.6234175 12.2928932,16.2328932 L5.70710678,9.64710678 C5.31658249,9.25658249 5.31658249,8.62341751 5.70710678,8.23289322 L6.17366391,7.76633609 C6.5638871,7.3761129 7.19645744,7.37576799 7.58710594,7.7655654 Z"
          id="Shape"
          fill={color}
          fillRule="nonzero"
          transform="translate(13.000000, 12.000000) scale(-1, 1) rotate(-270.000000) translate(-13.000000, -12.000000) "
        ></path>
      </g>
    </svg>
  )
}

ArrowRight.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

ArrowRight.defaultProps = {
  color: "#000000",
  width: 24,
  height: 24,
}

export default ArrowRight
