import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import ArrowRight from "../icons/arrow-right"
import Logo from "../../images/logo_icon.png"
import LogoWebp from "../../images/breadcrumb-list.webp"

const styles = theme => ({
  root: {
    width: "100%",
    whiteSpace: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "28px",
    flexWrap: "wrap",
    textAlign: "left",
    minHeight: "50px",
  },

  listItem: {
    marginRight: "7px",
    alignItems: "center",
    justifyContent: "flex-start",
    whiteSpace: "normal",
    verticalAlign: "text-bottom",
    lineHeight: 1.8,
    position: "relative",
  },

  link: {
    color: "#5A5B62",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "7px",
  },

  current: {
    fontSize: "12px",
    color: "#181819",
    fontWeight: "bold",
  },

  logo: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
    position: "absolute",
    top: "-1px",
  },

  spacer: {
    marginRight: "16px",
  },
})

/**
 * パンくずリストの構造化マークアップをレンダリングする
 * @param {Object} list
 * @param {Object} current
 * @return {Helmet}
 */
const renderStructuredData = (list, current) => {
  let hierarchy = 0;
  let itemListElements = [];

  if (list) {
    // 上位階層のページ
    itemListElements = list.map((item, i) => {
      hierarchy += 1;
      return {
        "@type": "ListItem",
        "position": hierarchy,
        "name": item.text,
        "item": "https://keyman-db.smart-letter.com" + item.path
      };
    });
  }

  if (current) {
    // 現在のページ
    itemListElements.push({
      "@type": "ListItem",
      "position": hierarchy + 1,
      "name": current.text,
      "item": "https://keyman-db.smart-letter.com" + current.path
    });
  }

  if (itemListElements.length > 0) {
    const itemListElementsStr = JSON.stringify(itemListElements);
    return (
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": ${itemListElementsStr}
          }`}
        </script>
      </Helmet>
    );
  }
}

const BreadcrumbList = ({ classes, style, list, current }) => (
  <div className={classes.root} style={style}>
    {list &&
      list.map((item, i) => (
        <span className={classes.listItem} key={i}>
          <Link className={classes.link} to={item.path}>
            {(() => {
              if (item.path === "/") {
                return (
                  <span className={classes.spacer}>
                    <picture>
                      <source
                        srcSet={LogoWebp}
                        className={classes.logo}
                        height={18}
                        width={18}
                        type="image/webp"
                      />
                      <img alt="logo-icon" src={Logo} className={classes.logo} height={18} width={18}/>
                    </picture>
                  </span>
                )
              } else {
                return item.text
              }
            })()}
          </Link>
          <ArrowRight style={{verticalAlign: "inherit"}} height={15} width={15} color="#5A5B62" />
        </span>
      ))}
    <span className={classes.listItem}>
      <Link className={classes.current} to={current.path}>
        {current.text}
      </Link>
    </span>
    {renderStructuredData(list, current)}
  </div>
)

BreadcrumbList.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  list: PropTypes.array,
  current: PropTypes.object,
}

export default withStyles(styles)(BreadcrumbList)
